import './set-public-path.js';
import moment from 'moment-timezone';
import { redRockDate } from './redRockDate';

// helper function that pre-applies the correct parse string for you
moment.redRockDate = redRockDate;

/*
 * Exporting everything from moment so that code that does the following still works
 * import * as moment from 'moment'
 */

// eslint-disable-next-line import/export
export * from 'moment';
export default moment;
