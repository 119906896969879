import moment from 'moment-timezone';

export function redRockDate(incomingDate, locale, strict) {
  return moment(
    incomingDate,
    ['YYYY-MM-DDTHH:mm:ss:SSSZ', moment.ISO_8601],
    locale,
    strict,
  );
}
